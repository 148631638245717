import React from "react";
import Modal from "react-modal";
// import { BigNumber } from '@ethersproject/bignumber';

import "./FarmStatementCard.css";
import { useWallet } from "wallets/wallet";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { useStakeOption } from "contexts/StakeOption";
import LoadingButton from "components/LoadingButton/LoadingButton";
// import { formatUnits } from 'web3/utils';
// import {useCountdown} from 'hooks/useCountdown'
import Countdown from "react-countdown";
// import { render } from '@testing-library/react';

Modal.setAppElement("#root");

function FarmStatementCard() {
  const { isActive } = useWallet();

  // const { pool, token, startApprove, startWithdraw, startDeposit, startUnlock, approving, withdrawing, depositing, unlocking } = useStakeOption();
  const {
    pool,
    token,
    startApprove,
    startWithdraw,
    startDeposit,
    startUnlock,
    approving,
    withdrawing,
    depositing,
  } = useStakeOption();

  // const { stakedUnit, proportionOfPool, unlockTime, staked, inLimbo } = pool;
  const { proportionOfPool, unlockTime, staked } = pool;

  const { allowanceUnit } = token;
  // const { icon, decimals } = token.tokenMeta;
  const { icon } = token.tokenMeta;

  // const deposit = formatUnits(stakedUnit ?? BigNumber.from(0), decimals);
  // let unlockT = useCountdown((unlockTime as number) + 3600);

  const Completionist = () => <span>Ready to unlock</span>;
  // const renderer: React.FC<{days: any, hours: any, minutes: any , seconds: any, completed:any }> = ({ days,  hours , minutes, seconds, completed }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };
  let unlockT2 = ((unlockTime as number) + 432000) * 1000;

  let unlockT = (
    <span>
      Time to Unlock: <Countdown date={unlockT2} renderer={renderer} />
    </span>
  );

  let currTime =
    Math.round(new Date().getTime() / 1000) >= (unlockTime as number) + 432000;

  // let unTime: Date = new Date(unlockT)

  return (
    <>
      <div className="farm-statement-card-container">
        {icon}
        <p>Your Deposit</p>
        <div className="deposit-value-container">
          <div className="deposit-container">
            <h2 className="user-deposit">{staked}</h2>
          </div>

          <p className="percentage-container">
            {" "}
            (<span className="percentage-value">{proportionOfPool}%</span> of
            total) {(unlockTime as number) > 0 && !currTime ? unlockT : ""}
          </p>
        </div>

        {isActive &&
          (allowanceUnit && allowanceUnit.lte(0) ? (
            <>
              <div className="buttons-container">
                <LoadingButton
                  className="approve-farm-button loading-button"
                  text="Approve"
                  loading={approving}
                  onClick={startApprove}
                />
              </div>
            </>
          ) : (
            <div className="buttons-container">
              {/* { inLimbo && inLimbo.gte(0) ? */}
              {currTime && (unlockTime as number) > 0 ? (
                <LoadingButton
                  className="medium-button loading-button"
                  text="Withdraw"
                  loading={withdrawing}
                  onClick={startWithdraw}
                />
              ) : (
                <LoadingButton
                  className="medium-button loading-button"
                  text="Unlock"
                  loading={!currTime}
                  onClick={startUnlock}
                />
              )}
              {/* <LoadingButton
                            className="medium-button loading-button"
                            text="Unlock"
                            loading={unlocking}
                            onClick={startUnlock}
                        />
                        <LoadingButton
                            className="medium-button loading-button"
                            text="Withdraw"
                            loading={withdrawing}
                            onClick={startWithdraw}
                        /> */}
              <LoadingButton
                className="medium-button loading-button"
                text="Deposit"
                loading={depositing}
                onClick={startDeposit}
              />
            </div>
          ))}

        {!isActive && (
          <>
            <div className="buttons-container">
              <ConnectWalletButton />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default FarmStatementCard;
