import React from "react";

import ExternalLink from "components/ExternalLink/ExternalLink";

import "./FarmPanel.css";
import { useStakeOption } from "contexts/StakeOption";
// import { etherscanAddress, formatPrefix, formatUnits } from "web3/utils";
import { etherscanAddress, formatPrefix } from "web3/utils";

import BankEarnedCard from "components/BankEarnedCard/BankEarnedCard";
import FarmStatementCard from "components/FarmStatementCard/FarmStatementCard";
// import { BANKTokenMeta } from "web3/contracts/BANK";

import { formatUnits as etherFormatUnits } from "@ethersproject/units";

function FarmPanel() {
  const { token, pool } = useStakeOption();
  // const { maximumContributionUnit, totalStakedUnit,  totalRewardUnit, duration } = pool;
  const { totalStakedUnit, totalRewardUnit, duration, totalStaked } = pool;

  const { name, decimals } = token.tokenMeta;
  const etherscanLink = etherscanAddress(pool.contract.address);
  //   const dailyReward = 1;
  // formatPrefix(
  //     totalRewardUnit?.div(duration ? duration / 60 / 60 / 24 : 1),
  //     BANKTokenMeta.decimals
  // );
  const dailyReward =
    Number(etherFormatUnits(totalRewardUnit ?? 0)) /
    (duration ? duration / 60 / 60 / 24 : 1);

  //   console.log(totalRewardUnit, "dailyreward");

  //   console.log(totalRewardUnit, "tru");
  const totalStakedPrefixed = formatPrefix(totalStakedUnit, decimals);
  const totalStakedDollar = Number(totalStaked) * Number(token.priceInDAI);

  //   console.log(
  //     formatUnits(totalStakedUnit, BANKTokenMeta.decimals),
  //     "totalStakedDollar"
  //   );
  //   console.log(Number(token.priceInDAI), "lastprice");

  const APYpct =
    ((Number(dailyReward) * 365 * Number(token.TKNPrice)) / totalStakedDollar) *
    100;
  // const maximumContribution = formatUnits(maximumContributionUnit, decimals)

  return (
    <div className="farm-panel-container">
      <div className="farm-panel-header">
        <div className="farm-panel-instructions">
          <h2>{name}</h2>
          <p>Deposit {name} to earn LPI tokens</p>
          {/* <p className="farm-subtitle">Limit per whitelisted account is {maximumContribution} {name}</p> */}
          {/* <p className="farm-subtitle">subtitle</p> */}

          <div className="etherscan-container">
            <ExternalLink href={etherscanLink}>View Pool</ExternalLink>
          </div>
        </div>

        <div className="smallColumnDivider"></div>

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">APY</p>
          <p className="farm-stat">{APYpct.toFixed(2)}%</p>
          {/* {totalStakedUnit && (
            <p className="farm-subtitle">{`$${totalStakedDollar}`}</p>
          )} */}
        </div>

        <div className="smallColumnDivider"></div>

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">Total {name} Deposited</p>
          <p className="farm-stat">{totalStakedPrefixed}</p>
          {totalStakedUnit && (
            <p className="farm-subtitle">{`$${totalStakedDollar.toFixed(
              2
            )}`}</p>
          )}
        </div>

        <div className="smallColumnDivider"></div>

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">Daily Distribution</p>
          <p className="farm-stat">{dailyReward.toFixed(2)}</p>
          <p className="farm-subtitle">LPI per day</p>
        </div>
      </div>

      <div className="farm-panel-body">
        <div className="left-box">
          <BankEarnedCard />
        </div>

        <div className="columnDivider"></div>

        <div className="right-box">
          <FarmStatementCard />
        </div>
      </div>
      <div className="farm-panel-footer">
        <p>
          If you request to withdraw from the {name} pool, your entire stake is
          locked for 5 days and you will no longer receive rewards during this
          period.
        </p>
      </div>
    </div>
  );
}

export default FarmPanel;
