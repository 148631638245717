import React from "react";
import Card from "components/Card/Card";
import Panel from "components/Panel/Panel";
import PhaseInfo from "components/PhaseInfo/PhaseInfo";
// import { useTKNContract, TKNTokenMeta, DAITokenMeta, USDCTokenMeta, USDTTokenMeta, useDAIContract, useUSDCContract, useUSDTContract } from "web3/contracts/StakeTokens";
// import { useDAIPhase1Pool, useUSDTPhase1Pool, useUSDCPhase1Pool, useTKNPhase1Pool } from "web3/contracts/Phase1Pool";
import { useTKNContract, usePCSContract } from "web3/contracts/StakeTokens";
import { useTKNPhase1Pool, usePCSPhase1Pool } from "web3/contracts/Phase1Pool";
import StakeOptionProvider from "contexts/StakeOption";
import FarmPanel from "components/FarmPanel/FarmPanel";
// import { BANKTokenMeta } from "web3/contracts/BANK";
// import { formatPrefix, formatUnits } from "web3/utils";
// import { useCountdown, useCountdownQuick } from "hooks/useCountdown";
// import { useWhitelist } from "contexts/WhitelistContext";
// import { useWallet } from "wallets/wallet";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Countdown from "react-countdown";

import { formatUnits as etherFormatUnits } from "@ethersproject/units";

// import config from "assets/config.json";

const TKNPanel = () => {
  const token = useTKNContract();
  const pool = useTKNPhase1Pool();
  return (
    <StakeOptionProvider token={token} pool={pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const PCSPanel = () => {
  const token = usePCSContract();
  const pool = usePCSPhase1Pool();
  return (
    <StakeOptionProvider token={token} pool={pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

// const DAIPanel = () => {
//   const token = useDAIContract();
//   const pool = useDAIPhase1Pool();
//   return (
//     <StakeOptionProvider
//       token={token}
//       pool={pool}
//     >
//       <FarmPanel/>
//     </StakeOptionProvider>
//   );
// }

// const USDTPanel = () => {
//   const token = useUSDTContract();
//   const pool = useUSDTPhase1Pool();
//   return (
//     <StakeOptionProvider
//       token={token}
//       pool={pool}
//     >
//       <FarmPanel/>
//     </StakeOptionProvider>
//   );
// }

// const USDCPanel = () => {
//   const token = useUSDCContract();
//   const pool = useUSDCPhase1Pool();
//   return (
//     <StakeOptionProvider
//       token={token}
//       pool={pool}
//     >
//       <FarmPanel/>
//     </StakeOptionProvider>
//   );
// }

// const WhitelistedTitle = () => {
//   const { account } = useWallet();
//   const { tree } = useWhitelist();
//   const icon = account && tree?.hasProof(account) ? "✓" : "✗";
//   return <>
//     <h4>Phase 1</h4>
//     <span className="whitelist-badge">{icon} Whitelisted</span>
//   </>;
// }

const Phase1View: React.FC = () => {
  const tkn = useTKNContract();
  const pcs = usePCSContract();

  // const dai = useDAIContract();
  // const usdt = useUSDTContract();
  // const usdc = useUSDCContract();
  const tknPool = useTKNPhase1Pool();
  const pcsPool = usePCSPhase1Pool();

  // const daiPool = useDAIPhase1Pool();
  // const usdtPool = useUSDTPhase1Pool();
  // const usdcPool = useUSDCPhase1Pool();

  // const timeLeftToLaunch = useCountdownQuick(config.phase_1_start);

  const Completionist = () => <span>Round Over!</span>;
  // const renderer: React.FC<{days: any, hours: any, minutes: any , seconds: any, completed:any }> = ({ days,  hours , minutes, seconds, completed }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  // const timeLeftInPhase2 = useCountdown(Math.max(tknPool.periodFinish ?? 0, config.phase_1_end));
  // const timeLeftInPhase1 = <Countdown date={Math.max((tknPool.periodFinish as number * 1000) ?? 0, config.phase_1_end)} renderer={renderer} />;
  let timeLeft = tknPool.periodFinish as number;
  const timeLeftInPhase1 = <Countdown date={timeLeft} renderer={renderer} />;

  // const nowEpoch = (new Date()).getTime() / 1000;

  // const totalReward = daiPool.totalRewardUnit?.add(usdtPool.totalRewardUnit ?? 0).add(usdcPool.totalRewardUnit ?? 0);
  const totalReward = tknPool.totalRewardUnit?.add(
    pcsPool.totalRewardUnit ?? 0
  );

  // const dailyReward = formatPrefix(
  //   totalReward?.div(tknPool.duration ? tknPool.duration / 60 / 60 / 24 : 1),
  //   BANKTokenMeta.decimals
  // );

  const dailyReward =
    Number(etherFormatUnits(totalReward ?? 0)) /
    (tknPool.duration ? tknPool.duration / 60 / 60 / 24 : 1);

  // const tvlDAI = Number(formatUnits(daiPool.totalStakedUnit, dai.tokenMeta.decimals)) * Number(dai.priceInDAI ?? 1);
  // const tvlUSDT = Number(formatUnits(usdtPool.totalStakedUnit, usdt.tokenMeta.decimals)) * Number(usdt.priceInDAI ?? 1);
  // const tvlUSDC = Number(formatUnits(usdcPool.totalStakedUnit, usdc.tokenMeta.decimals)) * Number(usdc.priceInDAI ?? 1);
  const tvlTKN =
    Number(etherFormatUnits(tknPool.totalStakedUnit ?? 0)) *
    Number(tkn.priceInDAI ?? 0);

  const tvlPCS =
    Number(etherFormatUnits(pcsPool.totalStakedUnit ?? 0)) *
    Number(pcs.priceInDAI ?? 0);

  // const tvl = tvlDAI + tvlUSDT + tvlUSDC + tvlTKN;
  const tvl = tvlTKN + tvlPCS; //add more when needed

  return (
    <>
      <Panel>
        <Card
          title="Total Value Locked"
          content={tvl ? `$${tvl.toFixed(2)}` : "-"}
        />
        <Card
          title="Daily Distribution"
          content={dailyReward.toFixed(3) ?? "-"}
        />
        {/* { nowEpoch >= config.phase_1_start ? <Card
          title='Time left in Phase'
          content={timeLeftInPhase1}
        /> : <Card
          title='Time left to Launch'
          content={timeLeftToLaunch}
        />
        } */}
        <Card title="Time left in Round" content={timeLeftInPhase1} />
        {/* <Countdown date={timeLeft} renderer={renderer} onMount={onMount} /> */}

        <Card title="LPI Price" content={"$" + tkn.TKNPrice?.toFixed(3) ?? 0} />
      </Panel>

      {/* <PhaseInfo title={<WhitelistedTitle/>}> */}
      {/* <p>Goal of the first phase is to distribute tokens to a wide range of smaller, very active participants. The first phase will last 6 weeks.</p> */}
      {/* <p>Each day, 1,500.00 BANK will be distributed, or 500 BANK per pool. In total, 10,500 BANK will be distributed per week.</p> */}
      {/* <p>There will be a limit of 10,000 tokens deposited per pool.</p> */}
      {/* </PhaseInfo> */}

      {/* <PhaseInfo title={<h4>Phase q</h4>}> */}
      {/* <p>Goal of the first phase is to distribute tokens to a wide range of smaller, very active participants. The first phase will last 6 weeks.</p> */}
      {/* <p>Each day, 1,500.00 BANK will be distributed, or 500 BANK per pool. In total, 10,500 BANK will be distributed per week.</p> */}
      {/* <p>There will be a limit of 10,000 tokens deposited per pool.</p> */}
      {/* </PhaseInfo> */}

      <PhaseInfo title={<h4>Round Information</h4>}>
        <p>Each staking round will last for 2 weeks.</p>
        <p>LPI Pool - Single sided LPI staking</p>
        <p>
          BNB-LPI LP Pool - Staking for Liquidity tokens acquired from
          PancakeSwap. Liquidity can be added here:
          <ExternalLink href="https://pancakeswap.finance/add/BNB/0x3428ebf69d2db64a056c68871f27c9e0310f26c9">
            Add liquidity on PancakeSwap
          </ExternalLink>
        </p>
        <p>
          1 BNB-LPI LP = ~ {pcs.BNBperLP?.toFixed(4)}BNB +{" "}
          {pcs.LPIperLP?.toFixed(4)}LPI{" "}
        </p>
        <p>
          For more details:{" "}
          <ExternalLink href="https://lpi-dao.medium.com/lpi-doa-staking-platform-launch-b3490ee3fb2c">
            Click Here
          </ExternalLink>
        </p>
      </PhaseInfo>

      <TKNPanel />
      <PCSPanel />
      {/* <DAIPanel />
      <USDCPanel />
      <USDTPanel /> */}

      {/* <PhaseInfo title={<h4>Phase 2</h4>}>
        <p>The second phase is meant to offer a wide distribution to communities of the top DeFI protocols and to enable initial price discovery for the BANK token. The second phase will last 2 weeks.</p>
        <p>All limits on participation will be removed (including per address and per pool).</p>
        <p>Additionally, new non-stablecoin pools will be added. The full announcement will be available 1 week before the start of Phase 2.</p>
        <p>Each day, 1,500.00 BANK will be distributed.</p>
        <p>During phase 2, we will also incentivise a liquidity pool for BANK-ETH in order to allow better price discovery</p>
      </PhaseInfo> */}
    </>
  );
};

export default Phase1View;
