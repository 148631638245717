import React from 'react';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import './Footer.css';

// TODO Add the Middot
function Footer() {

    return(
        <div className="footer-container">
                <div className='footer-col'>
                    <ExternalLink href="https://lpi-dao.medium.com/">Medium</ExternalLink>
                </div>
                <div className='footer-col'>
                    <span>&#8226;</span> 
                </div>
                <div className='footer-col'>
                    <ExternalLink href="https://twitter.com/LpiDao">Twitter</ExternalLink>
                </div>
                <div className='footer-col'>
                    <span>&#8226;</span> 
                </div>
                <div className='footer-col'>
                    <ExternalLink href="https://t.me/LpiDaoOfficial">Telegram</ExternalLink>
                </div>
                <div className='footer-col'>
                    <span>&#8226;</span> 
                </div>
                <div className='footer-col'>
                    <ExternalLink href="https://github.com/LpiDao">GitHub</ExternalLink>
                </div>
        </div>
    )
}

export default Footer;