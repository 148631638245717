import React from "react";
import { BigNumber } from "@ethersproject/bignumber";

import { useReload } from "hooks/useReload";
import { useWallet } from "wallets/wallet";
import { useAsyncEffect } from "hooks/useAsyncEffect";

import deployment from "web3/deployment.json";
import Web3Contract from "web3/Web3Contract";
import { TokenMeta } from "web3/TokenMeta";
import { getGeckoURI } from "web3/utils";
import { formatUnits } from "@ethersproject/units";
// import { ChainId, Fetcher, Route, Token } from "@uniswap/sdk";

import TKNLogo from "assets/stablecoinlogos/LPI.png";
import USDCLogo from "assets/stablecoinlogos/USDC.png";
import USDTLogo from "assets/stablecoinlogos/USDT.png";
import DAILogo from "assets/stablecoinlogos/DAI.png";
import PCSLogo from "assets/stablecoinlogos/LPIBNB.png";
// import { useWeb3React } from "@web3-react/core";

import axios from "axios";

interface TokenContractData {
  tokenMeta: TokenMeta;
  balance?: string;
  balanceUnit?: BigNumber;
  allowance?: string;
  allowanceUnit?: BigNumber;
  priceInDAI?: string;
  TKNPrice?: number;
  BNBPrice?: number;
  LPIperLP?: number;
  BNBperLP?: number;
}

export interface TokenContract extends TokenContractData {
  contract: Web3Contract;
  reload(): void;
  approveSend(value: BigNumber): Promise<any>;
}

const initialData: Omit<TokenContractData, "tokenMeta"> = {
  balance: undefined,
  balanceUnit: undefined,
  allowance: undefined,
  allowanceUnit: undefined,
  priceInDAI: "1",
  TKNPrice: undefined,
  BNBPrice: undefined,
  LPIperLP: undefined,
  BNBperLP: undefined,
};

interface UseTokenContractProps {
  poolAddr: string;
  tokenMeta: TokenMeta;
}

export function useTokenContract({
  poolAddr,
  tokenMeta,
}: UseTokenContractProps): TokenContract {
  const [reload] = useReload();
  const wallet = useWallet();
  // const { library } = useWeb3React();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(tokenMeta.abi, tokenMeta.address, tokenMeta.name);
  }, [tokenMeta.abi, tokenMeta.name, tokenMeta.address]);

  React.useEffect(() => {
    contract.setProvider(wallet.provider);
  }, [contract, wallet.provider]);

  const [data, setData] = React.useState<TokenContractData>({
    ...initialData,
    tokenMeta,
  });

  useAsyncEffect(async () => {
    let balance: string | undefined;
    let balanceUnit: BigNumber | undefined;
    let allowance: string | undefined;
    let allowanceUnit: BigNumber | undefined;

    if (wallet.account) {
      [balanceUnit, allowanceUnit] = await contract.batch([
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "allowance",
          methodArgs: [wallet.account, poolAddr],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);

      balance =
        balanceUnit &&
        formatUnits(BigNumber.from(balanceUnit), tokenMeta.decimals);
      allowance =
        allowanceUnit &&
        formatUnits(BigNumber.from(allowanceUnit), tokenMeta.decimals);
    }

    // if (tokenMeta.name === "BNB-LPI LP") {
    //   let reserves: string = "aaa";
    //   let LPIInPool;
    //   let BNBInPool;

    //   let supply: string | undefined;
    //   [reserves, supply] = await contract.batch([
    //     {
    //       method: "getReserves",
    //       // transform: (value: string) => BigNumber.from(value),
    //     },
    //     {
    //       method: "totalSupply",
    //       transform: (value: string) => BigNumber.from(value),
    //     },
    //   ]);

    //   LPIInPool = formatUnits(BigNumber.from(reserves[0]), tokenMeta.decimals)
    //   BNBInPool = formatUnits(BigNumber.from(reserves[1]), tokenMeta.decimals)
    //   console.log(
    //     formatUnits(BigNumber.from(reserves[0]), tokenMeta.decimals),
    //     "reserves"
    //   );
    //   // console.log(reserves[0], "reserves");

    //   console.log(
    //     formatUnits(BigNumber.from(supply), tokenMeta.decimals),
    //     "supply"
    //   );
    // }

    setData((prevState) => ({
      ...prevState,
      balance,
      balanceUnit,
      allowance,
      allowanceUnit,
    }));
  }, [reload, wallet.account]);

  // useAsyncEffect(async () => {
  //   let priceInDAI: string = "1";
  //   const daiToken = new Token(
  //     ChainId.MAINNET,
  //     DAITokenMeta.address,
  //     DAITokenMeta.decimals
  //   );
  //   const token = new Token(
  //     ChainId.MAINNET,
  //     tokenMeta.address,
  //     tokenMeta.decimals
  //   );

  //   if (library === undefined || daiToken.equals(token)) {
  //     return;
  //   }

  //   try {
  //     const daiToToken = await Fetcher.fetchPairData(daiToken, token, library);
  //     const priceRoute = new Route([daiToToken], token);
  //     priceInDAI = priceRoute.midPrice.toSignificant(3);
  //   } catch (err) {
  //     console.error(`Failed to fetch token price of ${tokenMeta.name}`, err);
  //   }

  //   setData((prevState) => ({
  //     ...prevState,
  //     priceInDAI,
  //   }));
  // }, [reload, library]);

  useAsyncEffect(async () => {
    let TKNPrice = 0;
    let BNBPrice = 0;

    await axios
      .get(getGeckoURI("lpi-dao"), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        const price = res.data["lpi-dao"].usd;

        // console.log(price, "lpi price");
        TKNPrice = Number(price);
      });

    await axios
      .get(getGeckoURI("binancecoin"), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        const price = res.data["binancecoin"].usd;

        // console.log(price, "bnb price");
        BNBPrice = Number(price);
      });

    setData((prevState) => ({
      ...prevState,
      TKNPrice,
      BNBPrice,
    }));

    if (tokenMeta.name === "LPI") {
      let priceInDAI = String(TKNPrice);

      // console.log(TKNPrice, "metpriceinDAI");

      setData((prevState) => ({
        ...prevState,
        priceInDAI,
      }));
    }

    if (tokenMeta.name === "BNB-LPI LP") {
      let reserves;
      let LPIInPool;
      let BNBInPool;

      let supply;
      [reserves, supply] = await contract.batch([
        {
          method: "getReserves",
          // transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "totalSupply",
          // transform: (value: string) => BigNumber.from(value),
        },
      ]);

      LPIInPool = formatUnits(BigNumber.from(reserves[0]), tokenMeta.decimals); // CHANGE BACK 1 -> 0 FOR LIVE POOL
      BNBInPool = formatUnits(BigNumber.from(reserves[1]), tokenMeta.decimals); // CHANGE BACK 0 -> 1 FOR LIVE POOL
      supply =
        supply && formatUnits(BigNumber.from(supply), tokenMeta.decimals);

      // console.log(
      //   formatUnits(BigNumber.from(reserves[0]), tokenMeta.decimals),
      //   "reserves"
      // );
      // console.log(reserves[0], "reserves");
      // console.log(LPIInPool, "LPIInPool");
      // console.log(BNBInPool, "BNBInPool");
      // console.log(supply, "supply");
      // console.log(supply, "supply");
      // console.log(supply, "supply");

      let price =
        (Number(LPIInPool) * TKNPrice + Number(BNBInPool) * BNBPrice) /
        Number(supply);
      // console.log(price, "LP Price");

      let priceInDAI = String(price);

      let LPIperLP = Number(LPIInPool) / Number(supply);
      let BNBperLP = Number(BNBInPool) / Number(supply);

      console.log(LPIperLP, BNBperLP, "LP info");

      setData((prevState) => ({
        ...prevState,
        priceInDAI,
        LPIperLP,
        BNBperLP,
      }));
    }
  }, [reload]);

  const approveSend = React.useCallback(
    (value: BigNumber): Promise<any> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract
        .send("approve", [poolAddr, value], {
          from: wallet.account,
        })
        .then(reload);
    },
    [wallet.account, contract, poolAddr, reload]
  );

  return React.useMemo<TokenContract>(
    () => ({
      ...data,
      contract,
      reload,
      approveSend,
    }),
    [data, contract, reload, approveSend]
  );
}

// DAI

export const DAITokenMeta: TokenMeta = {
  abi: deployment.contracts.DAI.abi,
  address: deployment.contracts.DAI.address,
  decimals: 18,
  name: "DAI",
  icon: <img src={DAILogo} className="coin-logo" alt="logo" />,
};

export function useDAIContract(): TokenContract {
  return useTokenContract({
    tokenMeta: DAITokenMeta,
    poolAddr: deployment.contracts.DAIPool.address,
  });
}

// USDC

export const USDCTokenMeta: TokenMeta = {
  abi: deployment.contracts.USDC.abi,
  address: deployment.contracts.USDC.address,
  decimals: 6,
  name: "USDC",
  icon: <img src={USDCLogo} className="coin-logo" alt="logo" />,
};

export function useUSDCContract(): TokenContract {
  return useTokenContract({
    tokenMeta: USDCTokenMeta,
    poolAddr: deployment.contracts.USDCPool.address,
  });
}

// USDT
export const USDTTokenMeta: TokenMeta = {
  abi: deployment.contracts.USDT.abi,
  address: deployment.contracts.USDT.address,
  decimals: 6,
  name: "USDT",
  icon: <img src={USDTLogo} className="coin-logo" alt="logo" />,
};

export function useUSDTContract(): TokenContract {
  return useTokenContract({
    tokenMeta: USDTTokenMeta,
    poolAddr: deployment.contracts.USDTPool.address,
  });
}

//Token

export const TKNTokenMeta: TokenMeta = {
  abi: deployment.contracts.TKN.abi,
  address: deployment.contracts.TKN.address,
  decimals: 18,
  name: "LPI",
  icon: <img src={TKNLogo} className="coin-logo" alt="logo" />,
};

export function useTKNContract(): TokenContract {
  return useTokenContract({
    tokenMeta: TKNTokenMeta,
    poolAddr: deployment.contracts.TKNPool.address,
  });
}

//PCSToken

export const PCSTokenMeta: TokenMeta = {
  abi: deployment.contracts.PCSToken.abi,
  address: deployment.contracts.PCSToken.address,
  decimals: 18,
  name: "BNB-LPI LP",
  icon: <img src={PCSLogo} className="coin-logo" alt="logo" />,
};

export function usePCSContract(): TokenContract {
  return useTokenContract({
    tokenMeta: PCSTokenMeta,
    poolAddr: deployment.contracts.PCSPool.address,
  });
}
