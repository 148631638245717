import React, { useEffect } from "react";

import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useWallet } from "wallets/wallet";
import Web3Contract from "web3/Web3Contract";
import { BANKTokenMeta } from "web3/contracts/BANK";

import deployment from "web3/deployment.json";
import { formatUnits } from "@ethersproject/units";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import {
  DAITokenMeta,
  USDCTokenMeta,
  USDTTokenMeta,
  TKNTokenMeta,
} from "./StakeTokens";
import { TokenMeta } from "web3/TokenMeta";
import { formatPrefix } from "web3/utils";
// import { useWhitelist } from 'contexts/WhitelistContext';

// import ethers from "ethers";

interface Phase1PoolData {
  currentReward: any;
  periodFinish?: number;
  rewardPerToken?: number;
  totalStaked?: string;
  totalStakedUnit?: BigNumber;
  earnedUnit?: BigNumber;
  earned?: string;
  totalRewardUnit?: BigNumber;
  totalReward?: string;
  duration?: number;
  maximumContributionUnit?: BigNumber;
  maximumContribution?: string;
  stakedUnit?: BigNumber;
  staked?: string;
  proportionOfPool?: string;
  inLimbo?: BigNumber;
  unlocks?: number;
  unlockTime?: number;
}

export type Phase1Pool = Phase1PoolData & {
  contract: Web3Contract;
  reload: VoidFunction;
  stake(amount: BigNumberish): Promise<void>;
  withdraw(amount: BigNumberish): Promise<void>;
  claim: VoidFunction;
  exit: VoidFunction;
  unlock: VoidFunction;
};

const initialData: Omit<Phase1PoolData, "whitelistTree"> = {
  periodFinish: 1656669093000,
  rewardPerToken: undefined,
  totalStaked: "-",
  totalStakedUnit: undefined,
  earnedUnit: undefined,
  earned: "-",
  totalRewardUnit: undefined,
  totalReward: "-",
  duration: undefined,
  maximumContributionUnit: undefined,
  maximumContribution: "-",
  stakedUnit: undefined,
  staked: "-",
  proportionOfPool: "-",
  currentReward: undefined,
  inLimbo: undefined,
  unlocks: undefined,
  unlockTime: 0,
};

interface UsePhase1PoolProps {
  abi: any;
  addr: string;
  name: string;
  stakeTokenMeta: TokenMeta;
}

function usePhase1Pool({
  abi,
  addr,
  name,
  stakeTokenMeta,
}: UsePhase1PoolProps): Phase1Pool {
  const [reload] = useReload();
  const wallet = useWallet();
  // const { tree } = useWhitelist();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  React.useEffect(() => {
    contract.setProvider(wallet.provider);
  }, [contract, wallet.provider]);

  const [data, setData] = React.useState<Phase1PoolData>(initialData);

  useAsyncEffect(async () => {
    let [
      totalRewardUnit,
      periodFinish,
      rewardPerToken,
      totalStakedUnit,
      duration,
      maximumContributionUnit,
    ] = await contract.batch([
      {
        method: "getRewardForDuration",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "periodFinish",
        transform: (value: string) => Number(value) * 1000,
      },
      {
        method: "rewardPerToken",
        transform: (value: string) => Number(value),
      },
      {
        method: "totalSupply",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "duration",
        transform: (value: string) => Number(value),
      },
      {
        method: "maximumContribution",
        transform: (value: string) => BigNumber.from(value),
      },
    ]);

    // console.log(duration, "pduration");
    const totalStaked =
      totalStakedUnit && formatUnits(totalStakedUnit, stakeTokenMeta.decimals);

    // console.log(totalStakedUnit, "totalStakedUnit");
    // console.log(totalStaked, "totalStaked");

    const totalReward =
      totalRewardUnit && formatPrefix(totalRewardUnit, BANKTokenMeta.decimals);
    const maximumContribution =
      maximumContributionUnit &&
      formatUnits(maximumContributionUnit, stakeTokenMeta.decimals);

    // console.log(totalReward, "totalReward");
    // console.log(formatUnits(totalRewardUnit), "totalRewardUnit");

    setData((prevState) => ({
      ...prevState,
      periodFinish,
      rewardPerToken,
      totalStakedUnit,
      totalStaked,
      totalRewardUnit,
      totalReward,
      duration,
      maximumContributionUnit,
      maximumContribution,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let earnedUnit: BigNumber | undefined;
    let stakedUnit: BigNumber | undefined;
    let unlockTime: number | 0;
    let inLimbo: BigNumber | undefined;

    if (wallet.account) {
      [earnedUnit, stakedUnit, unlockTime, inLimbo] = await contract.batch([
        {
          method: "earned",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "unlocks",
          methodArgs: [wallet.account],
          transform: (value: string) => Number(value),
        },
        {
          method: "inLimbo",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);
    }

    const earned =
      earnedUnit && formatUnits(earnedUnit, BANKTokenMeta.decimals);
    const staked1 =
      stakedUnit && formatUnits(stakedUnit, stakeTokenMeta.decimals);
    const staked2 = inLimbo && formatUnits(inLimbo, stakeTokenMeta.decimals);

    // console.log(staked1, "earned");
    // console.log(staked2, "limbo");
    // console.log(Math.round(new Date().getTime() / 1000), "date");

    let staked: string | undefined;
    if (Number(staked1) > 0 && staked1 !== undefined) {
      staked = staked1;
    } else if (Number(staked2) > 0 && staked2 !== undefined) {
      staked = staked2;
    }

    setData((prevState) => ({
      ...prevState,
      earnedUnit,
      earned,
      stakedUnit,
      staked,
      unlockTime,
    }));
  }, [reload, wallet.account]);

  useEffect(() => {
    console.log(Number(formatUnits(data.stakedUnit ?? 0)), "stakedunit");
    console.log(
      String(formatUnits(data.totalStakedUnit ?? 0)),
      "totalstakedunit"
    );

    // const proportionOfPool = percentageFromBigNumbers(
    //   data.stakedUnit,
    //   data.totalStakedUnit
    // );

    const proportionOfPool = String(
      (
        (Number(formatUnits(data.stakedUnit ?? 0)) /
          Number(formatUnits(data.totalStakedUnit ?? 0))) *
        100
      ).toFixed(5)
    );

    console.log(proportionOfPool, "proportionofpool");

    setData((prevState) => ({
      ...prevState,
      proportionOfPool,
    }));
  }, [data.stakedUnit, data.totalStakedUnit]);

  useAsyncEffect(async () => {
    let currentReward: BigNumber | undefined;

    if (wallet.account) {
      [currentReward] = await contract.batch([
        {
          method: "earned",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);
    }

    setData((prevState) => ({
      ...prevState,
      currentReward,
    }));
  }, [reload, wallet.account]);

  const stake = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      // const proof: string[] = tree.getProof(wallet.account);

      //   return contract.send('stakeWithProof', [amount, proof], {
      //     from: wallet.account,
      //   }).then(reload);
      // }, [wallet.account, tree, contract, reload]);

      return contract
        .send("stake", [amount], {
          from: wallet.account,
        })
        .then(reload);
    },
    [wallet.account, contract, reload]
  );

  const withdraw = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("withdraw", [BigNumber.from(amount)], {
          from: wallet.account,
        })
        .then(reload);
    },
    [contract, reload, wallet.account]
  );

  const claim = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("getReward", [], {
        from: wallet.account,
      })
      .then(reload);
  }, [wallet.account, contract, reload]);

  const exit = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("exit", [], {
        from: wallet.account,
      })
      .then(reload);
  }, [wallet.account, contract, reload]);

  const unlock = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("unlock", [], {
        from: wallet.account,
      })
      .then(reload);
  }, [wallet.account, contract, reload]);

  return React.useMemo<Phase1Pool>(
    () => ({
      ...data,
      contract,
      reload,
      stake,
      withdraw,
      claim,
      exit,
      unlock,
    }),
    [data, contract, reload, stake, withdraw, claim, exit, unlock]
  );
}

export function useDAIPhase1Pool(): Phase1Pool {
  return usePhase1Pool({
    abi: deployment.contracts.DAIPool.abi,
    addr: deployment.contracts.DAIPool.address,
    name: "DAI_PHASE1_POOL",
    stakeTokenMeta: DAITokenMeta,
  });
}

export function useUSDCPhase1Pool(): Phase1Pool {
  return usePhase1Pool({
    abi: deployment.contracts.USDCPool.abi,
    addr: deployment.contracts.USDCPool.address,
    name: "USDC_PHASE1_POOL",
    stakeTokenMeta: USDCTokenMeta,
  });
}

export function useUSDTPhase1Pool(): Phase1Pool {
  return usePhase1Pool({
    abi: deployment.contracts.USDTPool.abi,
    addr: deployment.contracts.USDTPool.address,
    name: "USDT_PHASE1_POOL",
    stakeTokenMeta: USDTTokenMeta,
  });
}

export function useTKNPhase1Pool(): Phase1Pool {
  return usePhase1Pool({
    abi: deployment.contracts.TKNPool.abi,
    addr: deployment.contracts.TKNPool.address,
    name: "LPI_PHASE1_POOL",
    stakeTokenMeta: TKNTokenMeta,
  });
}

export function usePCSPhase1Pool(): Phase1Pool {
  return usePhase1Pool({
    abi: deployment.contracts.PCSPool.abi,
    addr: deployment.contracts.PCSPool.address,
    name: "PCS_PHASE1_POOL",
    stakeTokenMeta: TKNTokenMeta,
  });
}
