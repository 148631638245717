import React from "react";
import Loader from "react-spinners/CircleLoader";

import "./LoadingButton.css";

interface LoadingButtonProps {
  loading: boolean;
  text: string;
  onClick?: VoidFunction;
  className?: string;
}

const LoadingButton = ({
  loading,
  onClick,
  text,
  className,
}: LoadingButtonProps) => {
  return (
    <button
      type="submit"
      className={className ?? "loading-button"}
      onClick={onClick}
      value={text}
      disabled={loading}
    >
      {loading && <Loader color="#FFF" size={20} />}
      {/* {loading ? text + "ing" : text} */}

      {loading && text === "Approve"
        ? "Approving"
        : loading
        ? text + "ing"
        : text}
    </button>
  );
};

export default LoadingButton;
